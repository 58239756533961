import * as React from 'react';
import Header from '../components/Common/Header';
import Intro from '../components/Adopt/Intro';
import Faqs from '../components/Adopt/Faqs';
import Layout from '../components/Layout/index';
import Apply from '../components/Common/Apply';
import { Seo } from '../components/Seo';
import Resources from '../components/Common/Resources';

const AdoptPage = () => {
  const data = [
    // {
    //   title: 'Application To Adopt Embryos',
    //   link: 'https://storage.googleapis.com/dega.factly.in/nedc/2015/12/embryo-adoption-form.pdf',
    // },
    //{
    //  title: 'NEDC FEE Schedule',
    //  link: 'https://www.embryodonation.org/NEDC_Fee_Schedule_Updated_1.2.24.pdf',
    //},
    {
      title: 'Instructions For Embryo Recipient Letter To Donors',
      link: 'https://storage.googleapis.com/dega.factly.in/nedc/2018/10/instructions-for-embryo-recipient-letter-to-donors.pdf',
    },
    {
      title: 'Resources For Infertility and Pregnancy Loss',
      link: 'https://www.embryodonation.org/Resources_for_infertility_and_pregnancy_loss.pdf'
    },
    {
      title: 'NEDC Fee Schedule',
      link: 'https://www.embryodonation.org/NEDC_Fee_Schedule_Feb_2025.pdf'
    },
  ];
  return (
    <Layout>
      <Seo
        title="Adoption of Embryos: Get Your Questions Answered Here. | National Embryo Donation Center"
        description="Embryo adoption allows infertile couples the opportunity to build a family through adopting embryos and experiencing pregnancy"
      />
      <div>
        <Header
          title="Adoption"
          description="The Gift of Life."
          video="https://www.youtube.com/watch?v=z4xKC7CpOZ8"
          background={'/images/header/larry-crayton-cf1cN3ofKUM-unsplash.png'}
        />
        <Intro />
        <Faqs />
        <Resources
          data={data}
          description={`We know this can be an exciting time and you probably have a lot of questions about the embryo adoption process. We’ve compiled some helpful resources here for your benefit but, if you have any questions or need additional information, please do not hesitate to contact us at <b>865-777-2013</b> or call toll free at <b>866-585-8549.</b>`}
          title="Adoption Resources"
        />
        <Apply />
      </div>
    </Layout>
  );
};

export default AdoptPage;
